<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      d="M4.434.154c.307 0 .586.165.735.426l.05.103 5.067 12.538h10.755l2.722-6.475h-9.027a.847.847 0 0 1-.84-.74l-.007-.106c0-.431.323-.787.74-.84l.107-.006h10.302c.57 0 .966.546.815 1.076l-.035.099-3.434 8.167a.847.847 0 0 1-.668.511l-.112.007H9.715a.847.847 0 0 1-.735-.426l-.05-.103L3.864 1.846H1a.847.847 0 0 1-.84-.74L.154 1c0-.432.322-.788.74-.84L1 .154h3.434z"
    />
    <path
      d="M14.917 21.196c0 1.312-1.134 2.376-2.53 2.376-1.397 0-2.531-1.064-2.531-2.376 0-1.312 1.134-2.376 2.53-2.376 1.397 0 2.53 1.064 2.53 2.376M22.507 21.196c0 1.312-1.133 2.376-2.53 2.376-1.397 0-2.53-1.064-2.53-2.376 0-1.312 1.133-2.376 2.53-2.376 1.397 0 2.53 1.064 2.53 2.376"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
